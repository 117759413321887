'use client';
import { Box, Heading, Text } from '@chakra-ui/layout';
import { As } from '@chakra-ui/react';
import { getImageProps } from 'next/image';
import Link from 'next/link';
import React from 'react';
import { ReactNode } from 'react';
import slugify from 'react-slugify';

import { DateService } from '@/services/date.service';
import { directusImageLoader } from '@/utils/loaders';

import Styles from './NewsFeedCard.module.scss';

type LinkComponentProps = {
  href: string;
  role: string;
  className: string;
  children?: string | ReactNode;
};

const LinkComponent = ({
  href = '/blog/latest-news',
  role = 'link',
  className = 'newsFeedImgBlock',
  children,
}: LinkComponentProps) => {
  return (
    <Link href={href} role={role} className={className}>
      {children}
    </Link>
  );
};

type HeadingComponentProps = {
  as: string;
  className: string;
  color?: string;
  marginBottom?: { [key: string]: string };
  children: any;
};

const HeadingComponent = ({
  as = 'h4',
  className = 'titleSm',
  marginBottom = { base: '4', md: '5' },
  children,
  color = 'white',
}: HeadingComponentProps) => {
  return (
    <Heading
      as={as as As}
      colo={color}
      className={className}
      marginBottom={marginBottom}
    >
      {children}
    </Heading>
  );
};

type TextComponentProps = {
  className: string;
  children: any;
};

const TextComponent = ({
  className = 'titleText',
  children,
}: TextComponentProps) => {
  return <Text className={className}>{children}</Text>;
};

const NewsCard = ({
  image,
  title,
  slug,
  date_to_publish,
  color = 'white',
}: {
  image: string;
  title: string;
  slug: string;
  date_to_publish: string;
  color?: string;
}) => {
  const common = { alt: 'Hero', width: 400, height: 369 };
  const imageKeys = { mobileKey: 'image-312-312', desktopKey: 'image-400-369', loader: directusImageLoader };
  const {
    props: { srcSet: mobile },
  } = getImageProps({
    ...common,
    src: image ? `${image ?? ''}?key=${imageKeys.mobileKey}` : '',
  });
  const {
    props: { srcSet: desktop, ...rest },
  } = getImageProps({
    ...common,
    src: image ? `${image ?? ''}?key=${imageKeys.desktopKey}` : '',
  });

  return (
    <Box className={Styles.newsFeedCard}>
      <LinkComponent
        href={`/blog/${slug ? slug : slugify(title)}`}
        role="link"
        className={Styles.newsFeedImgBlock}
      >
        <picture className={Styles.newsFeedImage}>
          <source media="(max-width: 575px)" srcSet={mobile} />
          <source media="(min-width: 576px)" srcSet={desktop} />
          <img {...rest} alt="hero-banner-image" />
        </picture>
      </LinkComponent>
      <Box
        className={Styles.headingElement}
        paddingX={{ base: 6 }}
        paddingY={{ base: 10 }}
      >
        <HeadingComponent
          as="h4"
          className={Styles.titleSm}
          marginBottom={{ base: '4', md: '5' }}
        >
          <LinkComponent
            href={`/blog/${slug ? slug : slugify(title)}`}
            role="link"
            className={color === 'white' ? Styles.linkWhite : ''}
          >
            {title}
          </LinkComponent>
        </HeadingComponent>
        <TextComponent className={color === 'white' ? Styles.titleText : ''}>
          {DateService.formatDate(date_to_publish)}
        </TextComponent>
      </Box>
    </Box>
  );
};

export default NewsCard;

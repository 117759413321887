'use client';
import { Box, Container, Flex, Heading, Text } from '@chakra-ui/layout';

import Styles from './LatestVideos.module.scss';

const LatestVideos = ({ playlist }: { playlist: string }) => {
  return (
    <>
      <Box className={Styles.latestVideosWrapper} paddingY={'100px'}>
        <Container maxW={1280} width={'100%'} paddingX={{ base: 6, '2xl': 0 }}>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            flexWrap={'wrap'}
            alignItems={{ base: 'start', md: 'center' }}
            marginBottom={{ base: 16, md: 10 }}
            className={Styles.latestVideosHeader}
          >
            <Box className={Styles.headingElement}>
              <Text fontSize="xs" textTransform={'uppercase'} marginBottom={4}>
                LATEST VIDEOS FOR TIPS AND ADVICE
              </Text>
              <Heading
                as="h2"
                textTransform={'capitalize'}
                className={Styles.titleLg}
              >
                Videos
              </Heading>
            </Box>
          </Flex>
          <iframe
            id="ytplayer"
            type="text/html"
            width="100%"
            height="556"
            src={playlist}
            frameborder="0"
            allowfullscreen
          />
        </Container>
      </Box>
    </>
  );
};

export default LatestVideos;
